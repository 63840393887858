export const mechanismTopics = [
  {
    title: 'Theory',
    topics: [
      {
        id: 1,
        name: 'Introduction',
      },
    ],
  },
  {
    title: 'Mechanism & Examples',
    topics: [
      {
        id: 2,
        name: 'Check if a number is a power of 2',
      },
      {
        id: 3,
        name: 'Count Ones in Binary Representation',
      },
      {
        id: 4,
        name: 'Check if ith Bit is Set',
      },
      {
        id: 5,
        name: 'Generate All Possible Subsets',
      },
      {
        id: 6,
        name: 'Find the largest power of 2',
      },
      {
        id: 7,
        name: 'Trailing Zeroes',
      },
      {
        id: 8,
        name: 'Iterate It',
      },
    ],
  },
];

export const mechanismPointerTopics = [
  {
    title: 'Theory',
    topics: [
      {
        id: 1,
        name: 'Introduction',
      },
    ],
  },
  {
    title: 'Mechanism & Examples',
    topics: [
      {
        id: 2,
        name: 'Move Zeroes',
      },
      {
        id: 3,
        name: 'Books',
      },
      {
        id: 4,
        name: 'Two Sum',
      },
      {
        id: 5,
        name: 'Minimum Size Subarray Sum',
      },
    ],
  },
];
