import { Tab } from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { Box } from '@material-ui/system';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import {
  isAdmin,
  isProgramManager,
} from '../../../ChatDashboard/ChatDashboard';
import AssignmentsReport from './AssignmentsReport';
import AttendanceReport from './AttendanceReport';
import { fetchProgramEnrollmentsUrl, getHeaders } from '../../../../apis/urls';
import ErrorNotifier from '../../../ToastNotifications/ErrorNotifier';
import { getUserType } from 'src/configs/auth';

export default function Reports() {
  const [value, setValue] = useState('1');
  const [learners, setLearners] = useState({});
  const currentProgram = useSelector((state) => state.programs.currentProgram);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const userRole = getUserType();

  useEffect(() => {
    if (currentProgram) {
      fetchLearners();
    }
    return () => {};
  }, [currentProgram]);

  function fetchLearners() {
    axios
      .get(fetchProgramEnrollmentsUrl(), {
        headers: getHeaders(currentProgram?.programId),
      })
      .then((res) => {
        const tmpLearners = {};
        res.data?.forEach((enrollment, index) => {
          if (
            enrollment?.user?.role === 'student' &&
            enrollment?.program_access === 'allowed'
          ) {
            tmpLearners[enrollment?.user?.id] = {
              id: enrollment?.user?.id,
              name: enrollment?.user?.name,
            };
          }
        });
        setLearners(tmpLearners);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(err.response?.data?.message || err.message);
      });
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      {hasError && (
        <ErrorNotifier
          message={errorMessage}
          setHasError={setHasError}
        />
      )}
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList
            onChange={handleChange}
            variant='scrollable'
            scrollButtons='auto'
          >
            <Tab
              label='Assignments'
              value='1'
            />
            {userRole === 'admin' ||
              (userRole === 'program_manager' && (
                <Tab
                  label='Attendance'
                  value='2'
                />
              ))}
          </TabList>
        </Box>
        <TabPanel value='1'>
          <AssignmentsReport />
        </TabPanel>
        <TabPanel value='2'>
          <AttendanceReport allLearners={learners} />
        </TabPanel>
      </TabContext>
    </Box>
  );
}
