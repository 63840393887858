// @ts-nocheck
import React from 'react';
import Dialog from '@mui/material/Dialog';
import EfPipelineOfferLetter from '../ScreeningTest/EfPipelineOfferLetter';

const OfferLetterModal = ({
  showOfferLetter,
  setShowOfferLetter,
  offerLetter,
}) => {
  const handleClose = () => {
    setShowOfferLetter(false);
  };

  return (
    <Dialog
      open={showOfferLetter}
      onClose={handleClose}
    >
      <EfPipelineOfferLetter
        {...offerLetter}
        shouldOverflow
      />
    </Dialog>
  );
};

export default OfferLetterModal;
