// @ts-nocheck
import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  Grid,
  Paper,
  Stack,
  TextField,
  CircularProgress,
} from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import EditIcon from '@mui/icons-material/Edit';
import { BASE_URL, getHeaders } from 'src/apis/urls';
import axios from 'axios';
import { useEfPipeline } from 'src/layouts/EfPipelineContext/EfPipelineContext';
import ErrorNotifier from '../ToastNotifications/ErrorNotifier';
import NewSopStrength from './NewSopStrength';

const tips = [
  'Career goals',
  'Skills and strengths',
  'Why this program',
  'Passion for the field',
  'Achievements',
  'Learning objectives',
];

const RevisitSOP = () => {
  const { formId, sopResponse, setShowSopRevisit } = useEfPipeline();
  const [isEditing, setIsEditing] = useState(false);
  const [statement, setStatement] = useState(sopResponse);
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const trackButtonClick = async ({ source, eventName }) => {
    const body = {
      source,
      event_name: eventName,
      type: 'sop_revisit',
    };

    await axios
      .post(`${BASE_URL}/api/eligibility-forms/${formId}/activities`, body, {
        headers: getHeaders(),
      })
      .then((res) => {
        if (res?.data?.message.toLowerCase() === 'ok') {
          setLoading(false);
          setIsEditing(false);
          setShowSopRevisit(false);
        }
      })
      .catch((err) => {
        setHasError(true);
        setErrorMessage('Error submitting SOP');
      });
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleStatementChange = (event) => {
    setStatement(event.target.value);
  };

  const shouldDisableResubmit = () => {
    return (
      isEditing &&
      (loading || statement.trim() === '' || statement === sopResponse)
    );
  };

  const handleSubmitClick = async () => {
    setLoading(true);

    const body = {
      source: 'revisit_sop',
      event_name: 'sop_revisit_completed',
      type: 'sop_revisit',
      sop_response: statement,
    };

    await axios
      .patch(`${BASE_URL}/api/eligibility-forms/${formId}/sop`, body, {
        headers: getHeaders(),
      })
      .then((res) => {
        if (res?.data?.status === 'ok') {
          setLoading(false);
          setIsEditing(false);
          setShowSopRevisit(false);
        }
      })
      .catch((err) => {
        setHasError(true);
        setErrorMessage('Error submitting SOP');
      });
  };

  return (
    <>
      {hasError && (
        <ErrorNotifier
          message={errorMessage}
          setHasError={setHasError}
        />
      )}
      <Stack>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '80vh',
            padding: 2,
          }}
        >
          <Paper
            elevation={3}
            sx={{
              padding: { xs: 2, sm: 3, md: 4, lg: 6 },
              maxWidth: { xs: 720, lg: 900 },
              width: '100%',
              borderRadius: 2,
              margin: 'auto',
            }}
          >
            {/* Heading Section */}
            <Typography
              variant='h5'
              fontWeight='bold'
              gutterBottom
              sx={{
                fontSize: { xs: '1.25rem', sm: '1.5rem', lg: '2rem' },
                color: '#1976d2',
              }}
            >
              Revisit your Statement of Purpose
            </Typography>
            <Typography
              variant='body1'
              sx={{
                mb: 2,
                textAlign: { xs: 'left', sm: 'left', md: 'left', lg: 'left' },
              }} // Centered text for lg screens
            >
              We noticed that your Statement of Purpose seems to be lacking &
              could be further strengthened. A well-crafted SOP usually contains
              certain elements that reflect your aspirations and objectives.
            </Typography>
            <Typography
              variant='body2'
              color='textSecondary'
              gutterBottom
              sx={{ mb: 2, fontSize: { lg: '1.1rem' } }}
            >
              Most applicants include these key points, which seem to be missing
              from your SOP:
            </Typography>

            {/* Tips Section */}
            <Grid
              container
              spacing={1}
              sx={{ mb: 2 }}
            >
              {tips.map((tip, index) => (
                <Grid
                  item
                  xs={12}
                  sm={4}
                  key={index}
                  display='flex'
                  alignItems='center'
                  gap={1}
                >
                  <StarIcon
                    fontSize='small'
                    sx={{ color: '#FFD700', mr: 1 }}
                  />
                  <Typography
                    variant='body2'
                    sx={{ fontSize: { lg: '1rem' } }}
                  >
                    {tip}
                  </Typography>
                </Grid>
              ))}
            </Grid>

            {/* Statement Box or TextField */}
            <Box
              sx={{
                padding: 3,
                backgroundColor: '#eaf6ff',
                borderRadius: 1,
                mb: 2,
                fontSize: { xs: '0.875rem', sm: '1rem', lg: '1.1rem' },
              }}
            >
              {isEditing ? (
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  value={statement}
                  onChange={handleStatementChange}
                  variant='outlined'
                  disabled={loading}
                />
              ) : (
                statement
              )}
            </Box>

            <NewSopStrength sop={statement} />
            {/* Button Section */}
            <Box
              display='flex'
              justifyContent='center'
              gap={2}
              flexWrap='wrap'
            >
              <Button
                variant='outlined'
                color='secondary'
                sx={{
                  textTransform: 'none',
                  fontSize: { xs: '0.8rem', sm: '0.9rem', lg: '1rem' },
                  minWidth: 140,
                  padding: '6px 16px',
                  display: isEditing && 'none',
                }}
                onClick={() =>
                  trackButtonClick({
                    source: 'revisit_sop',
                    eventName: 'sop_revisit_not_interested',
                  })
                }
              >
                Not interested
              </Button>
              <Button
                variant='contained'
                color='primary'
                sx={{
                  textTransform: 'none',
                  fontSize: { xs: '0.8rem', sm: '0.9rem', lg: '1rem' },
                  minWidth: 140,
                  padding: '6px 16px',
                }}
                onClick={isEditing ? handleSubmitClick : handleEditClick}
                disabled={shouldDisableResubmit()}
                endIcon={!loading && !isEditing ? <EditIcon /> : null}
              >
                {loading ? (
                  <CircularProgress
                    size={20}
                    color='inherit'
                  />
                ) : isEditing ? (
                  'Re-submit your SOP'
                ) : (
                  'Edit your SOP'
                )}
              </Button>
            </Box>
          </Paper>
        </Box>
      </Stack>
    </>
  );
};

export default RevisitSOP;
