// @ts-nocheck
import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Box, Stack, Typography } from '@mui/material';
import Image5 from '../../assets/pop-up_5.png';

const NoFurtherExtensionPopup = ({ showPopup, handleClosePopup }) => {
  return (
    <Dialog
      maxWidth='sm'
      fullWidth
      open={showPopup}
      onClose={handleClosePopup}
      PaperProps={{
        style: {
          maxWidth: '500px',
          height: 'fit-content',
        },
      }}
    >
      <img
        style={{
          maxWidth: '500px',
        }}
        src={Image5}
        alt='no-futher-extension'
      />

      <Stack
        spacing={1.5}
        padding='1rem'
        maxWidth='inherit'
      >
        <Typography
          variant='h4'
          component='h4'
          sx={{
            fontFamily: 'Inter',
            flexWrap: 'wrap',
          }}
        >
          <b>Time cannot be extended</b>
        </Typography>

        <Typography
          variant='body1'
          component='p'
          sx={{
            fontFamily: 'Inter',
            flexWrap: 'wrap',
          }}
        >
          To maintain the efficiency and exclusivity of our Fastrack program,
          the time is fixed and cannot be extended.
        </Typography>

        <Typography
          variant='body1'
          component='p'
          sx={{
            fontFamily: 'Inter',
            flexWrap: 'wrap',
          }}
        >
          Most people complete the Fastrack application within 12 minutes
        </Typography>

        <Typography
          variant='body1'
          component='p'
          sx={{
            fontFamily: 'Inter',
            flexWrap: 'wrap',
          }}
        >
          Missing this time frame will require you to proceed with the standard
          online screening interview process, which has a lower selection rate
          compared to the Fastrack approach.
        </Typography>
      </Stack>
    </Dialog>
  );
};

export default NoFurtherExtensionPopup;
