// @ts-nocheck
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Typography,
  TextField,
  Button,
  Stack,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { LoadingButton } from '@material-ui/lab';
import axios from 'axios';
import moment from 'moment';
import { getUserName } from '../../configs/auth';
import { getHeaders, postProgramFeedback } from '../../apis/urls';
import ErrorNotifier from '../ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../ToastNotifications/SuccessNotifier';
import { getRecommendationScaleQuestion } from './feedbackQuestions';

const initialState = {
  recommendation_scale: '',
  how_can_we_improve: '',
};

export default function NPSFormS30Modal({
  onClose,
  lastSubmittedFormResponse,
  setLastSubmittedFormResponse,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const program = useSelector((state) => state.programs?.currentProgram);
  const showSkipButton = lastSubmittedFormResponse?.show_skip_button;

  const [formValues, setFormValues] = useState(initialState);

  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  // const [loadingForSkipButton, setLoadingForSkipButton] = useState(false);
  const [loadingForSubmitButton, setLoadingForSubmitButton] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSendToPGMBot = async (scale, message) => {
    const userName = getUserName();

    const formattedMessage = `
      ${':octagonal_sign: '}: NPS form feedback
Learner: ${userName}
Program: ${program.name}
Rating: ${scale}
Message: ${message}
    `;

    const webhookURL =
      'https://discord.com/api/webhooks/1245341020741304361/g22q_iOEBTRGxraTDzK122E_JU8g-gG86yvgrW8awfcYLAKc86db5JzBr9IibfF66ZSG';

    try {
      axios.post(webhookURL, { content: formattedMessage });
    } catch (error) {
      console.error('Error sending message to Discord:', error);
    }
  };

  const shouldAllowSubmit = () => {
    return (
      formValues.how_can_we_improve !== '' &&
      formValues.recommendation_scale !== ''
    );
  };

  const handleSubmit = async (e, isSkipButtonClicked = false) => {
    setLoadingForSubmitButton(true);
    e.preventDefault();

    if (
      formValues.recommendation_scale &&
      formValues.recommendation_scale <= 6 &&
      !isSkipButtonClicked
    ) {
      handleSendToPGMBot(
        formValues.recommendation_scale,
        formValues.how_can_we_improve
      );
    }

    const body = { response: JSON.stringify(formValues) };

    axios
      .post(postProgramFeedback(), body, {
        headers: getHeaders(program?.programId),
      })
      .then((res) => {
        setIsSuccess(true);
        setSuccessMessage('Feedback Submitted Successfully');
        setLastSubmittedFormResponse({
          last_submitted_at: res.data?.submitted_at,
          show_form: false,
          show_referal: res.data?.show_referal,
          show_frustration_modal: res.data?.show_frustration_modal,
          show_first_form: res.data?.show_first_form,
        });
        setFormValues(initialState);
      })
      .catch((e) => {
        console.log(e);
        setHasError(true);
        setErrorMessage(
          e.response?.data?.message ||
            'An error occurred while submitting the Feedback'
        );
      })
      .finally(() => {
        setLoadingForSubmitButton(false);
      });
  };

  return (
    <>
      {hasError && (
        <ErrorNotifier
          message={errorMessage}
          setHasError={setHasError}
        />
      )}
      {isSuccess && (
        <SuccessNotifier
          message={successMessage}
          setIsSuccess={setIsSuccess}
        />
      )}

      <Dialog open>
        <DialogTitle>
          <Typography
            variant='h5'
            color='black'
          >
            We need your valuable feedback
          </Typography>
        </DialogTitle>

        <DialogContent>
          <Stack spacing={4}>
            {getRecommendationScaleQuestion(
              formValues.recommendation_scale,
              handleChange,
              isMobile
            )}

            <Stack spacing={0.5}>
              <Typography>How can we improve?</Typography>
              <TextField
                value={formValues.how_can_we_improve}
                onChange={handleChange}
                multiline
                name='how_can_we_improve'
                rows={3}
                fullWidth
                margin='normal'
                placeholder='Type here...'
                required
              />
            </Stack>

            <Stack
              direction='row-reverse'
              width='inherit'
              spacing={1}
            >
              <LoadingButton
                variant='contained'
                onClick={(e) => handleSubmit(e)}
                loading={loadingForSubmitButton}
                size='small'
                sx={{
                  boxShadow: 'none',
                  borderRadius: '0.3rem',
                  background: '#1976d2',
                  ':hover': {
                    background: '#1976d2',
                  },
                }}
                disableElevation
                disabled={!shouldAllowSubmit()}
              >
                Submit
              </LoadingButton>
              {/* {showSkipButton && (
                <LoadingButton
                  variant='outlined'
                  size='small'
                  onClick={(e) => handleSubmit(e, true)}
                  loading={loadingForSkipButton}
                  sx={{
                    boxShadow: 'none',
                    borderRadius: '0.3rem',
                    color: '#1976d2',
                    ':hover': {
                      color: '#1976d2',
                    },
                  }}
                  disableElevation
                >
                  Remind me later
                </LoadingButton>
              )} */}
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
}
