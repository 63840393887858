// @ts-nocheck
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box } from '@mui/material';
import { useEfPipeline } from '../../layouts/EfPipelineContext/EfPipelineContext';
import { getActiveForm, getHeaders } from '../../apis/urls';
import ErrorNotifier from '../ToastNotifications/ErrorNotifier';
import LoadingScreen from '../../pages/LoadingScreen';
import ActiveFormComponent from './ActiveFormComponent';

export default function EligibilityForm() {
  const {
    setFormId,
    formId,
    setMessage,
    showConversationFlow,
    showScreeningTest,
    showFastrackOfferLetter,
    setShowConversationFlow,
    setShowScreeningTest,
    setShowFastrackOfferLetter,
    setNotifyForCoupons,
    setShowSopRevisit,
    setSopResponse,
  } = useEfPipeline();
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [invitationMessage, setInvitationMessage] = useState('');

  useEffect(() => {
    fetchActiveForm();
  }, []);

  const fetchActiveForm = () => {
    setLoading(true);
    axios
      .get(getActiveForm(), {
        headers: getHeaders(),
      })
      .then((res) => {
        const {
          form_id,
          message,
          show_screening_test = false,
          show_conversation_flow = false,
          show_offer_letter = false,
          show_coupons = false,
          nudge_for_better_sop = false,
          sop_response,
        } = res.data;
        if (!form_id) {
          setMessage(message);
        } else {
          setFormId(form_id);
          if (nudge_for_better_sop === true) {
            setSopResponse(sop_response);
          }
          setInvitationMessage(message);
          setShowSopRevisit(nudge_for_better_sop);
          setShowScreeningTest(show_screening_test);
          setShowConversationFlow(show_conversation_flow);
          setShowFastrackOfferLetter(show_offer_letter);
          setNotifyForCoupons(show_coupons);
        }
      })
      .catch((e) => {
        console.log(e);
        setHasError(true);
        setErrorMessage(
          e.response?.data?.message || 'This form is no longer active.'
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const shouldShowEligibilityForm = () => {
    return (
      formId &&
      !showConversationFlow &&
      !showScreeningTest &&
      !showFastrackOfferLetter
    );
  };

  return (
    <>
      {hasError && (
        <ErrorNotifier
          message={errorMessage}
          setHasError={setHasError}
        />
      )}
      <LoadingScreen loading={loading} />
      {shouldShowEligibilityForm() && (
        <ActiveFormComponent invitationMessage={invitationMessage} />
      )}
    </>
  );
}
