import React from 'react';
import TechniqueSaga from '../../pages/TechniqueSaga';
import NewTechniqueSaga from '../../pages/NewTechniqueSaga';
import { useSelector } from 'react-redux';
import { shouldShowNewSessionsUI } from '../utils/common';

export default function NewTechnique() {
  const currentProgram = useSelector((state) => state.programs.currentProgram);
  const shouldShowNewTechnique = shouldShowNewSessionsUI(
    currentProgram?.programId
  );
  return (
    <>{shouldShowNewTechnique ? <NewTechniqueSaga /> : <TechniqueSaga />}</>
  );
}
