import {
  Button,
  Modal,
  Paper,
  Popper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import { Box } from '@material-ui/system';
import Radio from '@mui/material/Radio';
import axios from 'axios';
import React, { useState, useEffect, useContext } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  deleteCodeProblemTestcaseURL,
  fetchCodeProblemDetailsURL,
  getHeaders,
  updateCodeProblemURL,
} from '../../../apis/urls';
import ErrorNotifier from '../../ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../../ToastNotifications/SuccessNotifier';
import AddTestcase from './AddTestcase';
import PreviousPage from '../../../pages/PreviousPage';
import MarkdownEditor from '../../MarkdownEditor/MarkdownEditor';
import TopicsList from '../../../pages/TopicsList';
import TopicsDropdown from '../../../pages/TopicsDropdown';
import CodeSkeleton from './CodeSkeleton';
import IdealCodeSolution from './IdealCodeSolution';

export default function DetailedProblemAdminView({
  selectedProblem,
  setSelectedProblem,
}) {
  const [problemDetails, setProblemDetails] = useState({});
  const [updatedProblemDetails, setUpdatedProblemDetails] = useState({});
  const [allSolutions, setAllSolutions] = useState({});
  const [selectedTopicIds, setSelectedTopicIds] = useState([]);
  const [testcases, setTestcases] = useState([]);
  const [allSkeletons, setAllSkeletons] = useState({});
  const [openTestcase, setOpenTestcase] = useState(false);
  const [updateTestcase, setUpdateTestcase] = useState(false);
  const [selectedDefaultTestcaseId, setSelectedDefaultTestcaseId] = useState(
    problemDetails?.default_testcase?.id ?? -1
  );
  const [updateProblem, setUpdateProblem] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [language, setLanguage] = useState({
    languageId: 54,
    languageName: 'C++ (GCC 9.2.0)',
  });

  useEffect(() => {
    if (Object.keys(problemDetails).length > 0) {
      setSelectedTopicIds(getTopicIds());
    }
    return () => {};
  }, [problemDetails]);

  useEffect(() => {
    axios
      .get(fetchCodeProblemDetailsURL(selectedProblem?.id), {
        headers: getHeaders(),
      })
      .then((res) => {
        const solutions = {};
        (res.data?.code_solutions ?? []).forEach((sol) => {
          solutions[sol.language.judge0_language_id] = {
            id: sol.id,
            code: sol.code,
            languageName: sol.language.name,
            languageId: sol.language.judge0_language_id,
          };
        });

        let topics = [];
        if (res.data.topics_entities && res.data.topics_entities.length > 0) {
          topics = res.data.topics_entities.map((entity) => entity.topic);
        }

        const skeletons = {};
        res.data.code_skeletons?.forEach((skeleton) => {
          skeletons[skeleton.language.judge0_language_id] = {
            id: skeleton.id,
            skeleton_header: skeleton.skeleton_header,
            skeleton_solution_class: skeleton.skeleton_solution_class,
            skeleton_driver_code: skeleton.skeleton_driver_code,
            skeleton_define_class: skeleton.skeleton_define_class,
            skeleton_class_end: skeleton.skeleton_class_end,
            skeleton_expected_solution: skeleton.skeleton_expected_solution,
          };
        });

        setProblemDetails({
          ...problemDetails,
          id: selectedProblem?.id,
          title: res.data?.title,
          description: res.data?.description,
          defaultTestcase: res.data?.default_testcase,
          topics,
        });
        setSelectedDefaultTestcaseId(res.data?.default_testcase?.id ?? -1);
        setAllSkeletons(skeletons);
        setTestcases(res?.data?.testcases);
        setAllSolutions(solutions);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(err.response?.data?.message || err.message);
      });
    return () => {};
  }, [openTestcase]);

  const getTopicIds = () => {
    return problemDetails?.topics?.map((topic) => topic.id);
  };

  const submitUpdatedProblem = (e) => {
    const body = { ...updatedProblemDetails };
    if (selectedTopicIds.length > 0) {
      // atleast one topic is necessary for every problem.
      body.topic_ids = selectedTopicIds;
    }
    if (updateTestcase) {
      body.default_testcase_id = selectedDefaultTestcaseId;
    }

    axios
      .patch(updateCodeProblemURL(selectedProblem.id), body, {
        headers: getHeaders(),
      })
      .then((res) => {
        let topics = [];
        if (res.data.topics_entities && res.data.topics_entities.length > 0) {
          topics = res.data.topics_entities.map((entity) => entity.topic);
        }

        setProblemDetails({
          ...problemDetails,
          title: res.data?.title,
          description: res.data?.description,
          topics,
        });
        setUpdatedProblemDetails({});
        setUpdateProblem(false);
        setIsSuccess(true);
        setSuccessMessage('Problem updated.');
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(err.response?.data?.message || err.message);
      });
  };

  const handleTestcaseDelete = (e, tid) => {
    axios
      .delete(deleteCodeProblemTestcaseURL(selectedProblem.id, tid), {
        headers: getHeaders(),
      })
      .then((res) => {
        setIsSuccess(true);
        setSuccessMessage('Testcase successfully deleted');
        setTestcases(
          testcases.filter((testcase, index) => testcase.id !== tid)
        );
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(err.response?.data?.message || err.message);
      });
  };

  const getEditButtons = () => {
    return (
      <Stack
        direction='row'
        spacing={1}
        display='flex'
        justifyContent='flex-end'
      >
        <Button
          variant='contained'
          onClick={(e) => {
            setUpdatedProblemDetails(getProblemDetailsClone());
            setUpdateProblem(!updateProblem);
          }}
        >
          {updateProblem ? 'Cancel problem update' : 'Update problem'}
        </Button>
        {updateProblem && (
          <Button
            variant='contained'
            onClick={(e) => submitUpdatedProblem()}
          >
            Save problem
          </Button>
        )}
      </Stack>
    );
  };

  const getProblemDetailsClone = () => {
    const prob = {
      id: problemDetails.id,
      description: problemDetails.description,
      title: problemDetails.title,
    };

    setSelectedTopicIds(getTopicIds());
    return prob;
  };

  const showTopics = () => {
    function getTopicNames() {
      return problemDetails?.topics?.map((topic) => topic?.name);
    }
    return (
      <>
        {!updateProblem ? (
          <TopicsList topics={getTopicNames()} />
        ) : (
          <TopicsDropdown
            {...{
              selectedTopicIds,
              setSelectedTopicIds,
              setErrorMessage,
              setHasError,
            }}
          />
        )}
      </>
    );
  };

  return (
    <Stack sx={{ ml: 2, mr: 2 }}>
      {hasError && (
        <ErrorNotifier
          message={errorMessage}
          setHasError={setHasError}
        />
      )}
      {isSuccess && (
        <SuccessNotifier
          message={successMessage}
          setIsSuccess={setIsSuccess}
        />
      )}

      <Box
        display='flex'
        justifyContent='space-between'
      >
        <PreviousPage handlePrevPageClick={() => setSelectedProblem(false)} />
        {getEditButtons()}
      </Box>

      {Object.keys(problemDetails).length > 0 && (
        <Stack
          spacing={2}
          sx={{ mt: 2 }}
        >
          <Typography variant='h3'>{problemDetails?.title}</Typography>

          {showTopics()}

          <MarkdownEditor
            content={
              updateProblem
                ? updatedProblemDetails.description
                : problemDetails?.description
            }
            setContent={
              updateProblem
                ? (value) =>
                    setUpdatedProblemDetails({
                      ...updatedProblemDetails,
                      description: value,
                    })
                : () => {}
            }
            showEditor={updateProblem}
          />

          <CodeSkeleton
            allSkeletons={allSkeletons}
            problemDetails={problemDetails}
            setLanguage={setLanguage}
            language={language}
            setAllSkeletons={setAllSkeletons}
            problemId={selectedProblem.id}
            setErrorMessage={setErrorMessage}
            setHasError={setHasError}
            setSuccessMessage={setSuccessMessage}
            setIsSuccess={setIsSuccess}
            allSolutions={allSolutions}
            setAllSolutions={setAllSolutions}
            openTestcase={openTestcase}
            setOpenTestcase={setOpenTestcase}
          />
          {testcases?.length > 0 && (
            <DisplayTestcases
              testcases={testcases}
              handleTestcaseDelete={handleTestcaseDelete}
              problemDetails={problemDetails}
              updateTestcase={updateTestcase}
              setUpdateTestcase={setUpdateTestcase}
              selectedDefaultTestcaseId={selectedDefaultTestcaseId}
              setSelectedDefaultTestcaseId={setSelectedDefaultTestcaseId}
              submitUpdatedProblem={submitUpdatedProblem}
            />
          )}

          {Object.keys(allSkeletons)?.length > 0 && testcases.length === 0 && (
            <Typography
              variant='h6'
              textAlign='center'
              sx={{ pt: 8 }}
            >
              Next step is to add test cases.
            </Typography>
          )}
          <IdealCodeSolution
            {...{
              allSkeletons,
              language,
              allSolutions,
            }}
          />
        </Stack>
      )}
      {openTestcase && (
        <AddTestcase
          key={problemDetails?.id}
          pid={problemDetails?.id}
          setHasError={setHasError}
          setErrorMessage={setErrorMessage}
          setIsSuccess={setIsSuccess}
          setSuccessMessage={setSuccessMessage}
          openTestcase={openTestcase}
          setOpenTestcase={setOpenTestcase}
        />
      )}
    </Stack>
  );
}

function DisplayTestcases({
  testcases,
  handleTestcaseDelete,
  problemDetails,
  updateTestcase,
  setUpdateTestcase,
  selectedDefaultTestcaseId,
  setSelectedDefaultTestcaseId,
  submitUpdatedProblem,
}) {
  const handleUpdateDefaultTestcase = (type) => {
    if (type === 'save') {
      const oldDefaultTestcaseId = problemDetails?.defaultTestcase?.id ?? -1;
      if (oldDefaultTestcaseId !== selectedDefaultTestcaseId) {
        submitUpdatedProblem();
      }
    } else if (type === 'cancel') {
      setSelectedDefaultTestcaseId(problemDetails?.defaultTestcase?.id ?? -1);
    }
    setUpdateTestcase(!updateTestcase);
  };

  return (
    <Stack spacing={2}>
      <Stack
        direction='row'
        spacing={2}
        sx={{ display: 'flex', justifyContent: 'flex-end' }}
      >
        {updateTestcase && (
          <Button
            variant='contained'
            onClick={(e) => handleUpdateDefaultTestcase('cancel')}
          >
            Cancel
          </Button>
        )}
        <Button
          variant='contained'
          onClick={(e) =>
            handleUpdateDefaultTestcase(updateTestcase ? 'save' : 'update')
          }
        >
          {updateTestcase ? 'Save default testcase' : 'Update default testcase'}
        </Button>
      </Stack>

      <TableContainer
        component={Paper}
        sx={{ mt: 4 }}
      >
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell
                align='center'
                sx={{ width: 40 }}
              >
                {' '}
                Default testcase{' '}
              </TableCell>
              <TableCell align='center'> Input </TableCell>
              <TableCell align='center'>Expected output</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {testcases.length > 0 &&
              testcases.map((testcase, index) => (
                <TableRow>
                  <TableCell align='center'>
                    <Radio
                      disabled={!updateTestcase}
                      checked={testcase.id === selectedDefaultTestcaseId}
                      onClick={(e) => setSelectedDefaultTestcaseId(testcase.id)}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      multiline
                      defaultValue={testcase.input}
                      style={{ width: '100%', padding: 10 }}
                      inputProps={{ readOnly: true }}
                      rows={3}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      multiline
                      defaultValue={testcase.expected_output}
                      style={{ width: '100%', padding: 10 }}
                      inputProps={{ readOnly: true }}
                      rows={3}
                    />
                  </TableCell>
                  <TableCell align='center'>
                    <DeleteIcon
                      fontSize='large'
                      color='error'
                      sx={{ cursor: 'pointer' }}
                      onClick={(e) => {
                        const result = window.confirm(
                          'Are you sure you want to delete this testcase?'
                        );
                        if (result) {
                          handleTestcaseDelete(e, testcase.id);
                        }
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
}
