import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserEnrollmentsUrl, getHeaders } from 'src/apis/urls';
import { setShouldShowNewStudentLayout } from 'src/components/redux-store/slices/programSlice';
import ErrorNotifier from 'src/components/ToastNotifications/ErrorNotifier';
import { getUserType } from 'src/configs/auth';
import StudentScreenV2 from './StudentScreenV2';
import StudentScreen from './StudentScreen';
import {
  shouldShowNewSessionsUI,
  shouldShowNewStudentLayout,
} from 'src/components/utils/common';

export default function StudentScreenWrapper() {
  const userType = getUserType();
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const dispatch = useDispatch();
  const isNewLayout = useSelector(
    (state) => state.programs?.shouldShowNewStudentLayout
  );
  useEffect(() => {
    axios
      .get(fetchUserEnrollmentsUrl(), {
        headers: getHeaders(),
      })
      .then((res) => {
        if (!res.data || res.data.length === 0) {
          return;
        }
        const p = res.data.map((e) => {
          return {
            ...e.program,
            program_access: e.program_access,
          };
        });
        let allowedProgramCount = 0;
        let programSlug = '';
        let programId = null;

        Object.keys(p)?.forEach((id, index) => {
          if (p[id].program_access === 'allowed') {
            allowedProgramCount += 1;
            programId = p[id].id;
          }
        });
        if (isNewLayout == null) {
          if (userType === 'student' && allowedProgramCount === 1) {
            dispatch(
              setShouldShowNewStudentLayout(
                shouldShowNewStudentLayout(programId)
              )
            );
          } else {
            dispatch(setShouldShowNewStudentLayout(false));
          }
        }
      })
      .catch((e) => {
        console.log(e);
        setHasError(true);
        setErrorMessage(
          e.response?.data?.message ||
            'An error occured while fetching the programs'
        );
      });
  }, []);

  return (
    <div>
      {isNewLayout == null ? (
        'Loading...'
      ) : isNewLayout ? (
        <StudentScreenV2 />
      ) : (
        <StudentScreen />
      )}
    </div>
  );
}
