// @ts-nocheck
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Card, CardContent, Stack, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { getHeaders, openS3URL } from '../../apis/urls';
import { Box } from '@mui/material';

const NewSessionRecording = (details) => {
  const [recording, setRecording] = useState('');
  const currentProgram = useSelector((state) => state.programs.currentProgram);
  const session_recordings = useSelector(
    (state) => state.sessions.session_recordings
  );
  const resource = session_recordings.find(
    (recording) => recording.session_id === details?.details?.id
  );

  useEffect(() => {
    const openFileHandler = async () => {
      if (!resource) return;
      if (resource.resource_context === 'session_recording') {
        axios
          .get(openS3URL(resource.id), {
            headers: getHeaders(currentProgram?.programId),
          })
          .then((res) => {
            const { data } = res;
            setRecording(data.file);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    };

    openFileHandler();
    return () => {};
  }, []);

  return (
    <Box
      spacing={2}
      textAlign='center'
      marginTop='30px'
    >
      {recording ? (
        <Card sx={{ width: '100%' }}>
          <CardContent>
            <video
              width='100%'
              height='360'
              controls
            >
              <source
                src={recording}
                type='video/webm'
              />
              <track
                kind='captions'
                label='English'
                srcLang='en'
                src=''
              />
            </video>
          </CardContent>
        </Card>
      ) : (
        <Typography fontSize='18px'>
          The recording will be available within the next 1-2 days. Meantime if
          you have <br /> any queries, please reach out to your POC.
        </Typography>
      )}
    </Box>
  );
};

export default NewSessionRecording;
