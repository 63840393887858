import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import React from 'react';
import { allLanguages } from '../languages';

export function LanguageSelect({ setSelectedLanguage, selectedLanguage }) {
  const handleLanguageSelection = (event) => {
    setSelectedLanguage({
      languageId: allLanguages[event.target.value],
      languageName: event.target.value,
    });
  };

  return (
    <FormControl sx={{ m: 1 }}>
      <InputLabel id='language'>Language</InputLabel>
      <Select
        labelId='language'
        id='language'
        label='Language'
        sx={{
          width: '15rem',
          height: 40,
        }}
        value={selectedLanguage.languageName}
        onChange={handleLanguageSelection}
      >
        {Object.keys(allLanguages).map((languageName, index) => (
          <MenuItem
            value={languageName}
            key={index + 1}
          >
            {languageName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
