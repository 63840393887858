// @ts-nocheck
import React, { useRef, useState, useEffect } from 'react';
import { useEfPipeline } from '../../layouts/EfPipelineContext/EfPipelineContext';
import {
  Box,
  Stack,
  Step,
  StepLabel,
  Stepper,
  useMediaQuery,
  useTheme,
  Grid,
} from '@mui/material';
import SmallScreenOfferLetter from './SmallScreenOfferLetter';
import LargeScreenOfferLetter from './LargeScreenOfferLetter';
import SuccessScreen from '../eligibility-forms/SuccessScreen';
import axios from 'axios';
import { BASE_URL, getActiveLetter, getHeaders } from 'src/apis/urls';
import LoadingScreen from 'src/pages/LoadingScreen';
import FakeResponseAnalyzer from '../ScreeningTest/FakeResponseAnalyzer';
import NoFurtherExtensionPopup from './NoFurtherExtensionPopup';

const steps = ['Application form', 'Screening test', 'Offer letter'];

// https://developer.phonepe.com/v1/reference/check-status-api-1/#:~:text=If%20the%20payment%20status%20is%20Pending%2C%20then%20Check%20Status%20API%20should%20be%20called%20in%20the%20following%20interval%3A
const retryIntervals = [
  20000, // First check at 20-25 seconds
  3000,
  3000,
  3000,
  3000,
  3000,
  3000,
  3000,
  3000,
  3000, // Every 3 seconds for the next 30 seconds
  6000,
  6000,
  6000,
  6000,
  6000,
  6000,
  6000,
  6000,
  6000,
  6000, // Every 6 seconds for the next 60 seconds
  10000,
  10000,
  10000,
  10000,
  10000,
  10000,
  10000,
  10000,
  10000,
  10000, // Every 10 seconds for the next 60 seconds
  30000,
  30000,
  30000,
  30000,
  30000,
  30000,
  30000,
  30000,
  30000,
  30000, // Every 30 seconds for the next 60 seconds
  60000,
  60000,
  60000,
  60000,
  60000,
  60000,
  60000,
  60000,
  60000,
  60000, // Every 1 minute until timeout
];

const FastrackOfferLetter = () => {
  const {
    setShowFastrackOfferLetter,
    setMessage,
    showFakeResponseAnalyzerForScreeningTest,
    setShowFakeResponseAnalayerForScreeningTest,
  } = useEfPipeline();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTab = useMediaQuery('(max-width: 1200px)');
  const [loading, setLoading] = useState(false);
  const [olMessage, setOLMessage] = useState('');
  const [activeOLStatus, setActiveOLStatus] = useState('pending');
  const pollingStartTime = useRef(-1);
  const pollingIntervalIndex = useRef(-1);
  const maxTimeout = 5 * 60 * 1000; // 5 minutes in milliseconds;
  const [offerLetter, setOfferLetter] = useState({});
  const [extensionRequestCount, setExtensionRequestCount] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const [showConnectingScreen, setShowConnectingScreen] = useState(false);
  const [expiryExtensionMessage, setExpiryExtensionMessage] = useState('');

  useEffect(() => {
    if (!showFakeResponseAnalyzerForScreeningTest) {
      fetchActiveOfferLetter();
    }
  }, [showFakeResponseAnalyzerForScreeningTest]);

  const reset = () => {
    setShowFastrackOfferLetter(false);
    setMessage(
      'You need an invitation to apply to HeyCoach programs. If you are an existing learner, please check your login email id.'
    );
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const fetchActiveOfferLetter = async () => {
    setLoading(true);
    try {
      const res = await axios.get(getActiveLetter(), {
        headers: getHeaders(),
      });

      const { status = null, message } = res.data;
      if (!status) {
        reset();
        return;
      }

      setActiveOLStatus(status);
      setOLMessage(message);
      if (status !== 'success') {
        setOfferLetter({
          olId: res?.data?.offer_letter?.id,
          lname: res?.data?.lead_lname,
          fname: res?.data?.lead_fname,
          lead_owner: res.data.lead_owner,
          leadId: res?.data?.offer_letter?.lead_id,
          program: res?.data?.offer_letter?.response?.program,
          link: res?.data?.offer_letter?.response.payment_link,
          expires_at: res?.data?.offer_letter?.expires_at,
        });

        setExtensionRequestCount(
          res.data.offer_letter.extend_expiry_request_count ?? 0
        );
      }

      if (status === 'processing') {
        if (pollingStartTime.current === -1) {
          pollingStartTime.current = Date.now();
        }
        pollingIntervalIndex.current += 1;
        pollForTransactionStatus();
      }
    } catch (e) {
      console.log(e);
      setShowFastrackOfferLetter(false);
      setMessage(e?.response?.data?.message ?? 'Oops! Something went wrong.');
    } finally {
      setLoading(false);
    }
  };

  const pollForTransactionStatus = () => {
    let totalTimeElapsed = Date.now() - pollingStartTime.current;

    if (
      pollingIntervalIndex.current >= retryIntervals.length ||
      totalTimeElapsed >= maxTimeout
    ) {
      console.log('Max timeout reached or all retries done.');
      setMessage(
        "Couldn't get an update on your payment. Please contact your POC for more information."
      );
      return;
    }

    const delay = retryIntervals[pollingIntervalIndex.current];
    setTimeout(() => fetchActiveOfferLetter(), delay);
  };

  const requestCallback = async () => {
    try {
      const id = offerLetter.olId;
      const res = await axios.post(
        `${BASE_URL}/api/offer-letters/${id}/request_callback`,
        {
          lead_id: offerLetter.leadId,
          assignee: offerLetter.lead_owner,
          lead_name:
            (offerLetter.fname ?? '') +
            (offerLetter.lname ? ` ${offerLetter.lname}` : ''),
        },
        {
          headers: getHeaders(),
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

  const requestExtension = async () => {
    setLoading(true);
    try {
      const id = offerLetter.olId;
      const res = await axios.post(
        `${BASE_URL}/api/offer-letters/${id}/request_extension`,
        {},
        {
          headers: getHeaders(),
        }
      );

      const { message, extend_expiry_request_count, expires_at } = res.data;
      setExtensionRequestCount(extend_expiry_request_count);
      setOfferLetter((prev) => ({ ...prev, expires_at }));
      setExpiryExtensionMessage(message);
      setShowPopup(extend_expiry_request_count > 1);
    } catch (e) {
      console.log(e);
      setMessage(e?.response?.data?.message ?? 'Oops! Something went wrong.');
    } finally {
      setLoading(false);
    }
  };

  if (activeOLStatus === 'success') {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '10px',
          paddingBottom: '3rem',
          paddingTop: '2rem',
        }}
      >
        <SuccessScreen successMessage={olMessage} />;
      </div>
    );
  }

  return (
    <>
      {loading && <LoadingScreen loading={loading} />}

      {showPopup && (
        <NoFurtherExtensionPopup
          showPopup={showPopup}
          handleClosePopup={handleClosePopup}
        />
      )}

      <Box
        alignSelf='center'
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: '#e3f2fd',
          paddingTop: '6rem',
          paddingBottom: '2rem',
          paddingLeft: '2rem',
          paddingRight: '2rem',
          overflowY: 'auto',
          overflowX: 'auto',
          '@media (max-width: 500px)': {
            paddingTop: '1.5rem',
          },
          '@media (min-height: 700px)': {
            paddingTop: '3rem',
          },
        }}
      >
        {Object.keys(offerLetter).length > 0 && (
          <Grid
            container
            spacing={2}
            sx={{
              backgroundColor: 'white',
              borderRadius: '12px',
              width: {
                xs: '80vw',
                sm: '80vw',
                md: '84vw',
                lg: '80vw',
                xl: '80vw',
              },
              height: '100%',
              '@media (max-width: 1200px)': {
                height: 'fit-content',
                width: {
                  xs: '90vw',
                  sm: '85vw',
                  md: '80vw',
                },
                maxWidth: 'fit-content',
              },
              paddingTop: '3rem',
              paddingBottom: '3rem',
            }}
          >
            {!showConnectingScreen && (
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                }}
              >
                <Box sx={{ width: '90%', maxWidth: '90%' }}>
                  <Stepper
                    activeStep={2}
                    alternativeLabel={isMobile}
                  >
                    {steps.map((label, index) => (
                      <Step key={label}>
                        <StepLabel
                          sx={{
                            fontFamily: 'Inter',
                            color: '#1976d2',
                          }}
                        >
                          {label}
                        </StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </Box>
              </Grid>
            )}

            <Grid
              item
              xs={12}
              container
              direction='column'
              height='100%'
              style={{
                paddingLeft: 0,
              }}
            >
              {isTab ? (
                <SmallScreenOfferLetter
                  offerLetter={offerLetter}
                  activeOLStatus={activeOLStatus}
                  message={olMessage}
                  reset={reset}
                  expiryExtensionMessage={expiryExtensionMessage}
                  requestCallback={requestCallback}
                  requestExtension={requestExtension}
                  extensionRequestCount={extensionRequestCount}
                  showConnectingScreen={showConnectingScreen}
                  setShowConnectingScreen={setShowConnectingScreen}
                />
              ) : (
                <LargeScreenOfferLetter
                  offerLetter={offerLetter}
                  activeOLStatus={activeOLStatus}
                  message={olMessage}
                  reset={reset}
                  setExpiryExtensionMessage={setExpiryExtensionMessage}
                  expiryExtensionMessage={expiryExtensionMessage}
                  requestCallback={requestCallback}
                  requestExtension={requestExtension}
                  extensionRequestCount={extensionRequestCount}
                  showConnectingScreen={showConnectingScreen}
                  setShowConnectingScreen={setShowConnectingScreen}
                />
              )}
            </Grid>
          </Grid>
        )}
      </Box>
    </>
  );
};

export default FastrackOfferLetter;
