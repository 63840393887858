export const formatDate = (dateString) => {
  const date = new Date(dateString);
  if (Number.isNaN(date.getTime())) return dateString;

  // Function to get the suffix for the day
  const getDaySuffix = (day) => {
    if (day >= 11 && day <= 13) {
      return 'th';
    }
    switch (day % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  };

  // Format the date
  const options = {
    day: 'numeric',
    month: 'long',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  };

  const formatter = new Intl.DateTimeFormat('en-US', options);
  const formattedDate = formatter.format(date);

  // Extract day, month, and year
  const day = date.getDate();
  const month = date.toLocaleString('default', { month: 'long' });
  const year = date.getFullYear();

  // Get the suffix for the day
  const daySuffix = getDaySuffix(day);

  // Format the result
  const formattedResult = `${day}${daySuffix} ${month} ${year} ${formattedDate.slice(
    -11
  )}`;

  return formattedResult;
};

export const formatTimeDelta = (dateString) => {
  const now = new Date();
  const createdDate = new Date(dateString);
  const diffInMilliseconds = now - createdDate;
  const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));

  if (diffInMinutes < 1440) {
    return `Today`;
  } else {
    const daysAgo = Math.floor(diffInMinutes / 1440);
    if (daysAgo === 1) {
      return '1 day ago';
    }
    return `${daysAgo} days ago`;
  }
};

export const getStatusColor = (status) => {
  switch (status) {
    case 'open':
      return '#3399ff'; // Blue
    case 'closed':
      return '#ababab'; // Grey
    case 'in-progress':
      return '#ffff99'; // Yellow
    default:
      return '';
  }
};

export const getCategory = (category) => {
  switch (category) {
    case 'request_callback':
      return 'Request Callback';
    case 'doubt_solving_assistance':
      return 'Doubt Solving Assistance';
    case 'detractor':
      return 'Detractor';
    default:
      return 'Others';
  }
};

export const isMoreThan24Hours = (dateString) => {
  const now = new Date();
  const createdDate = new Date(dateString);
  const diffInMilliseconds = now - createdDate;
  return diffInMilliseconds > 24 * 60 * 60 * 1000;
};
