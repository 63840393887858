import Faster from '../../assets/Thnder.png';
import Profile from '../../assets/search.png';
import NoEMI from '../../assets/tag.png';
import Priority from '../../assets/time.png';
import Selection from '../../assets/Bookmark.png';
import Process from '../../assets/person.png';
export const WelcomeScreenContent = [
  {
    imgSrc: Faster,
    title: 'Faster results',
    description: 'Outrun 80% of the competition with fastrack.',
  },
  {
    imgSrc: Profile,
    title: 'Online profile evaluation',
    description: 'Get comprehensive online evaluation of your profile.',
  },
  {
    imgSrc: NoEMI,
    title: 'No-cost EMI on program fee',
    description: 'Flexible payment options on program fee after acceptance',
  },
  {
    imgSrc: Priority,
    title: 'Priority access',
    description: 'Get ahead with the early access to the program',
  },
  {
    imgSrc: Selection,
    title: 'Higher selection rate',
    description: 'Fastrack has a higher success rate than online interviews.',
  },
  {
    imgSrc: Process,
    title: 'Exclusive Process',
    description: 'Fastrack is shown only to select individuals who qualify.',
  },
];
