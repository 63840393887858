// @ts-nocheck
import { Stack, Typography, Box } from '@mui/material';
import React from 'react';

const FastrackOfferLetterCountdown = ({ time }) => {
  const getTimeSplit = () => {
    const days = Math.floor(time / (1000 * 60 * 60 * 24));
    const hours = Math.floor((time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((time % (1000 * 60)) / 1000);

    const dd = days < 10 ? `0${days}` : `${days}`;
    const hh = hours < 10 ? `0${hours}` : `${hours}`;
    const mm = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const ss = seconds < 10 ? `0${seconds}` : `${seconds}`;
    return {
      days: dd,
      hours: hh,
      minutes: mm,
      seconds: ss,
    };
  };

  const renderCountdown = () => {
    const timeSplit = getTimeSplit();
    const { hours, minutes, seconds, days } = timeSplit;

    const getDigitCard = (digit) => {
      return (
        <Box
          padding='4px 7px'
          sx={{
            background: '#ffeb3b',
            borderRadius: '5px',
            fontFamily: 'Inter',
            fontWeight: 'bold',
          }}
        >
          {Math.floor(digit)}
        </Box>
      );
    };

    const times = [];
    if (days > 0) {
      times.push(
        <Stack
          spacing={0.1}
          key='days'
        >
          <Stack
            direction='row'
            spacing={0.3}
          >
            {getDigitCard(days / 10)}
            {getDigitCard(days % 10)}
          </Stack>
          <Typography
            variant='caption'
            component='p'
            sx={{ fontFamily: 'Inter', fontWeight: 'bold' }}
          >
            days
          </Typography>
        </Stack>
      );
    }

    if (hours > 0) {
      times.push(
        <Stack
          spacing={0.1}
          key='hours'
        >
          <Stack
            direction='row'
            spacing={0.3}
          >
            {getDigitCard(hours / 10)}
            {getDigitCard(hours % 10)}
          </Stack>
          <Typography
            variant='caption'
            component='p'
            sx={{ fontFamily: 'Inter', fontWeight: 'bold' }}
          >
            hours
          </Typography>
        </Stack>
      );
    }

    times.push(
      <Stack
        spacing={0.1}
        key='minutes'
      >
        <Stack
          direction='row'
          spacing={0.3}
        >
          {getDigitCard(minutes / 10)}
          {getDigitCard(minutes % 10)}
        </Stack>
        <Typography
          variant='caption'
          component='p'
          sx={{ fontFamily: 'Inter', fontWeight: 'bold' }}
        >
          minutes
        </Typography>
      </Stack>
    );

    times.push(
      <Stack
        spacing={0.1}
        key='seconds'
      >
        <Stack
          direction='row'
          spacing={0.3}
        >
          {getDigitCard(seconds / 10)}
          {getDigitCard(seconds % 10)}
        </Stack>
        <Typography
          variant='caption'
          component='p'
          sx={{ fontFamily: 'Inter', fontWeight: 'bold' }}
        >
          seconds
        </Typography>
      </Stack>
    );

    return times.map((t, index) => {
      const isLast = index >= times.length - 1;
      return (
        <Stack
          direction='row'
          spacing={0.5}
          alignItems={'start'}
          key={index}
        >
          {t}
          {!isLast && (
            <Typography sx={{ fontFamily: 'Inter', fontWeight: 'bold' }}>
              :
            </Typography>
          )}
        </Stack>
      );
    });
  };

  return (
    <Stack
      direction='row'
      spacing={0.5}
    >
      {!isNaN(time) && renderCountdown()}
    </Stack>
  );
};

export default FastrackOfferLetterCountdown;
