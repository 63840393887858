export const bitwisOperators = [
  'AND (&): Performs a bitwise AND operation, setting a bit to 1 only if both corresponding bits are 1.',
  'OR (|): Performs a bitwise OR operation, setting a bit to 1 if at least one corresponding bit is 1.',
  "NOT (~): Inverts all bits in a number, representing the one's complement.",
  'XOR (^): Performs a bitwise XOR operation, setting a bit to 1 if the corresponding bits are different.',
  'LEFT SHIFT (<<): Shifts bits to the left, appending zeros at the right end (multiplication by powers of 2).',
  'RIGHT SHIFT (>>): Shifts bits to the right, discarding the rightmost bits (division by powers of 2).',
];

export const CheckIfNumberIsPowerOfTwoConstant = {
  ProblemStatement01:
    "You are given a number 'n', determine if this number is a power of 2 or not.",
  ProblemStatement02:
    'If n=4 you should return true since 4 is a power of 2. If n=7, you should return false since 7 is not a power of 2.',
  TraditionalApproach: {
    text1:
      'A straight way to approach this would be to keep on dividing by 2 and at any point you get an odd number other than 1, then that means the value is a power of 2. You have to address the edge case where for 0 you should return false.',
    text2:
      'For the above approach the time complexity that we will be getting is O(log n).',
  },
  BitManipulationApproach: {
    text01:
      "The number n if it is a power of 2, when represented in binary will have all elements as zero except the first one, for example: binary representation of 4 is 100, for 8 is 1000. Now, let's say the number 'n' is a power of 2 and let's see the binary representation of 'n-1', so for example: 4-1 = 3 whose binary representation is 011 and for 8-1=7 the binary representation is 0111. Thus, if n is a power of 2 then n-1 will have all its bits flipped with respect to n. So, AND operation of both these numbers would result in zero.",
    text02:
      'For the above code implementation the time complexity will be O(1) and space complexity will be O(1).',
  },
};

export const CheckIthBitSetConstant = {
  ProblemStatement: {
    text01:
      'Given a number n and a bit number i, check if the ith bit of N is set or not. A bit is called set if it is 1.',
  },
  TraditionalApproach: {
    text01:
      'This method leverages the modulo operation to extract the remainder of the number after dividing by the power of two corresponding to bit position plus one. A subsequent division by the power of two corresponding to the bit position yields either a 0 or 1.',
    text02: 'The time and space complexity for this is O(1).',
  },
  BitManipulationApproach01: {
    text: 'Use the bitwise AND operation to check if the ith bit is set in the binary representation of a number N.',
  },
  BitManipulationApproach02: [
    'For N = 20 (binary: 10100) and i = 2:',
    'N & (1 << 2) results in {10100} & {100} = {100} = 4 (non-zero), indicating the 2nd bit is set.',
  ],
  BitManipulationApproach03: [
    "Regardless of N's size or i's value, the bitwise AND operation takes constant time.",
    'The code efficiently checks if the ith bit is set in the binary representation of N.',
  ],
};

export const CountOnesBinaryConstant = {
  ProblemStatement: {
    text01:
      "You are given a number 'n', you have to find the number of ones, in the binary representation of 'n'.",
  },
  TraditionalApproach: {
    text01:
      'Traverse through the binary representation and do check how many 1s are there by using AND operation and shift operators.',
    text02:
      'The time complexity for the above approach is O(log n) and space complexity is O(1).',
  },
  BitManipulationApproach01: [
    "Utilises the relationship between a number 'x' and 'x-1' in binary representation.",
    "The operation n = n & (n - 1) flips the rightmost 1 in 'n' and bits to its right.",
    "Iterating through this process reduces 'n' to a number with fewer ones, increasing the count in each iteration.",
  ],
  BitManipulationApproach02: {
    title: 'For n = 23 (binary: 10111)',
    lists: [
      'n = 22 (binary: 10110), count = 1',
      'n = 20 (binary: 10100), count = 2',
      'n = 16 (binary: 10000), count = 3',
      'n = 0, count = 4',
    ],
  },
};

export const FindLargestPowerOfTwoConst = {
  example01: [
    'This property can be used to find the largest power of 2 less than or equal to N. How?',
    'If we somehow, change all the bits which are at right side of the most significant bit of N to 1, then the number will become x + (x-1) = 2 * x -1 , where x is the required answer.',
  ],
  example02: [
    'For eg',
    'Let’s say N = 21 = {10101}, here the most significant bit is the 4th one. (counting from 0th digit) and so the answer should be 16.',
    "So let's change all the right side bits of the most significant bit to 1. Now the number changes to",
    '{11111} = 31 = 2 * 16 -1 = Y (let’s say).',
  ],
  BitManipulationTimeComplexity: {
    text01:
      "Each of the bitwise operations will take a constant amount of time and at no place are using any extra space but only modifying 'N'.",
  },
};

export const GenerateAllPossibleSubsetsConst = {
  ProblemStatement: {
    text01:
      'Print all possible subsets of a given array. For example if the array provided is a = {1, 2, 3} then its possible subsets are:',
    text02:
      '{} , {1} , {2} , {3} , {1,2}, {1,3}, {2,3}, {1,2,3} which are in total 8.',
  },
  TraditionalApproach: {
    text01:
      'With the use of a traditional backtracking approach we can solve this where at every turn we either choose to include the element or do not choose to include.',
  },
  BitManipulationApproach01: {
    text01:
      'The bit-manipulation approach is easier as compared to back tracking. If we see we only have two choices, either we choose an element or we do not choose the element, since there are two choices that assign these values binary representation where 1 means choose and 0 means not choose. Now there will be 2^n subsets possible and we can have a unique binary representation for all these numbers.',
  },
  BitManipulationApproach02: [
    'For example A = {a, b, c}:',
    '0 = {}, 1 = {c}, 2 = {b}, 3 = {b, c}, 4 = {a}, 5 = {a, c}, 6 = {a, b}, 7 = {a, b, c}',
  ],
  TraditionalTimeComplexity: {
    text01:
      'We are generating all the subsets which will take 2^n operations where n is the size of array. Additionally we are pushing every subset which could be potentially of size n into our subsets array which takes O(n) time.',
    text02:
      'To store 2^n subsets we will have to utilise that space and in subset size could be the size of the array which is n so the total space complexity would be O(n x 2^n).',
  },
  BitManipulationTimeComplexity: {
    text01:
      'In the bit-manipulation approach also we are generating all the subsets and storing those subset in a subsets array whose size can be of potentially n. So the space and time complexity is the same which is O(nx2^n), but it is useful because there will be no overhead of recursive calls.',
  },
};

export const TrailingZeroesConst = {
  example01: [
    "For the given constraints (0 <= A <= 10^9), the possible time complexities for solving the problem of counting trailing zeros could range from O(log A) to O(1). Here's a breakdown:",
    'O(log A):',
    'If the solution involves repeatedly dividing A by 10 until it becomes 0, the time complexity will be logarithmic in the value of A.',
    'O(1):',
    'If the solution relies on bitwise operations or other constant-time operations, the time complexity can be constant.',
  ],
  example02: [
    'Check the last bit',
    'If it is 1 , break instantly',
    'Otherwise keep incrementing count because the last bit is zero.',
  ],
  example03: [
    'Convert the integer into the binary representation as a string',
    "Iterate through the characters from the right until the first '1' is encountered.",
    "As soon as a '1' is encountered while iterating from right to left, the loop terminates.",
    'The count at this point represents the number of trailing zeroes in the binary representation of the original integer A.',
  ],
  example04: [
    'The loop starts from the least significant bit and moves towards the most significant bit.',
    "It checks each bit, and if a '1' is encountered, it means the trailing zeros have ended, and the loop breaks.",
    "If a '0' is encountered, it increments the count, as '0' indicates a trailing zero.",
    'The count represents the number of trailing zeros in the binary representation of the input integer.',
  ],
  Intuition: [
    'The loop starts from the least significant bit and moves towards the most significant bit.',
    "It checks each bit, and if a '1' is encountered, it means the trailing zeros have ended, and the loop breaks.",
    "If a '0' is encountered, it increments the count, as '0' indicates a trailing zero.",
    'The count represents the number of trailing zeros in the binary representation of the input integer.',
  ],
  ConstraintAnalysis: {
    title:
      "For the given constraints (0 <= A <= 10^9), the possible time complexities for solving the problem of counting trailing zeros could range from O(log A) to O(1). Here's a breakdown:",
    content: [
      {
        title: 'O(log A)',
        pointer: [
          'If the solution involves repeatedly dividing A by 10 until it becomes 0, the time complexity will be logarithmic in the value of A.',
        ],
      },
      {
        title: 'O(1)',
        pointer: [
          'If the solution relies on bitwise operations or other constant-time operations, the time complexity can be constant.',
        ],
      },
    ],
  },
  explanation: [
    {
      title: 'Initialize Counter',
      pointer: {
        title: 'count = 0',
        lists: [
          'Initializes a counter variable count to zero. This variable will be used to count the trailing zeroes.',
          'A counter variable, count, is initialized to zero. This counter is pivotal in the algorithm, serving as a tally for the number of trailing zeros encountered in the binary string. Its initial value is set to zero to accurately reflect the count of trailing zeros as the algorithm progresses through the binary string.',
        ],
      },
    },
    {
      title: 'Iterate Through Binary String from Right to Left',
      pointer: {
        title: 'For i from length(binaryString) - 1 to 0:',
        lists: [
          'Initiates a loop that iterates through the characters of the binaryString from the rightmost (least significant bit) to the leftmost.',
          'The core of the algorithm is a for-loop that iterates through the binaryString in reverse order, starting from the last character (rightmost bit) to the first character (leftmost bit). This reverse traversal is deliberate, targeting the direct examination of trailing zeros without the need to inspect the entire string. It optimizes the search process, focusing solely on the segment of the binary string where trailing zeros are located.',
        ],
      },
    },
    {
      title: "Check for '0' Characters",
      pointer: {
        title: "If binaryString[i] is '0'",
        lists: [
          "Checks if the current character at position i in the binary string is '0'.",
          'If true, increments the count variable.',
          'Within the loop, a conditional check is performed on each character of the binary string. If the character at the current position, i, is a 0, it signifies the discovery of a trailing zero. Consequently, the count variable is incremented. This incrementation is a critical operation, as it updates the count to reflect the number of trailing zeros identified up to that point in the iteration.',
        ],
      },
    },
    {
      title: "Exit Loop When '1' is Encountered",
      pointer: {
        title: 'Else: Break',
        lists: [
          "Exits the loop when the first '1' is encountered in the binary string.",
          "The iteration continues until a 1 is encountered, signaling the end of the trailing zeros. This encounter prompts an else branch that executes a break statement. This break is strategic, effectively halting the loop and preventing further iteration. It's based on the understanding that once a 1 is found, no additional zeros to its left (in the binary string) will count as trailing zeros. Thus, it conservatively conserves computational resources by terminating the search early.",
        ],
      },
    },
    {
      title: 'Return Count',
      pointer: {
        title: 'Return count',
        lists: [
          'Returns the final count of trailing zeroes.',
          'Upon completion of the loop (and thus, the termination condition being met or the entire binary string being traversed), the algorithm concludes by returning the count variable. This variable now holds the total number of trailing zeros in the binary representation of the integer A, offering a precise count that has been meticulously tallied through the described process.',
        ],
      },
    },
  ],
  optimizedExplanation: [
    {
      title: 'Initialize Counter',
      pointer: {
        title: 'count = 0',
        lists: [
          'Initializes a counter variable count to zero. This variable will be used to count the trailing zeroes.',
          "The algorithm begins by initializing a counter variable, count, to zero. This counter is fundamental to the method's operation, as it is used to accumulate the number of trailing zeros found in the binary representation of A. Initializing count to zero ensures that the tally starts afresh, providing an accurate count as the algorithm progresses.",
        ],
      },
    },
    {
      title: 'Iterate Through Bits of the Integer',
      pointer: {
        title: 'For i from 0 to 31',
        lists: [
          'Initiates a loop that iterates through the 32 bits of the integer A.',
          'The next step involves a for-loop that iterates from 0 to 31, reflecting the 32 bits of the integer A. This loop is structured to sequentially access each bit of A, starting from the least significant bit (LSB) and moving towards the most significant bit (MSB). The iteration range is deliberately chosen to encompass all the bits of a standard 32-bit integer, ensuring that no bit is overlooked in the search for trailing zeros.',
        ],
      },
    },
    {
      title: 'Check i-th Bit using Bitwise AND',
      pointer: {
        title: 'If ((A >> i) & 1) == 1',
        lists: [
          'Right shifts the bits of A by i positions and checks if the least significant bit after the shift is 1.',
          'If true, it means the i-th bit is 1, and the loop is exited.',
          'Within the loop, the algorithm employs a bitwise AND operation combined with a right shift to inspect each bit of A. Specifically, A is right-shifted i positions, and the result is ANDed with 1 ((A >> i) & 1). This operation isolates the i-th bit, moving it to the LSB position and masking off all other bits. The condition checks if this isolated bit is 1. If so, it indicates that the i-th bit is 1, marking the end of any trailing zeros since bits are examined from least to most significant.',
        ],
      },
    },
    {
      title: "Exit Loop When '1' is Encountered",
      pointer: {
        title: 'Break',
        lists: [
          "Exits the loop when the first '1' is encountered in the binary representation of the integer.",
          "Upon encountering a 1 (when the condition ((A >> i) & 1) == 1 evaluates to true), the algorithm executes a break statement. This action prematurely exits the loop, as the discovery of a 1 signifies the end of trailing zeros. This break is crucial for the algorithm's efficiency, as it stops the iteration process once the objective (counting trailing zeros) is achieved, avoiding unnecessary checks of the remaining bits.",
        ],
      },
    },
    {
      title: 'Increment Counter',
      pointer: {
        title: 'Increment count',
        lists: [
          'If the i-th bit is not 1, increments the count variable.',
          'If the i-th bit is not 1 (indicating a 0), the loop continues, and the count variable is incremented. This incrementing occurs for each 0 encountered before the first 1, effectively tallying the number of trailing zeros in the binary representation of A.',
        ],
      },
    },
    {
      title: 'Return Count',
      pointer: {
        title: 'Return count',
        lists: [
          'Returns the final count of trailing zeroes.',
          "After the loop concludes (either from reaching the first 1 or completing all 32 iterations without finding a 1), the algorithm returns the count variable. This variable now holds the total count of trailing zeros in the integer A's 32-bit binary representation, providing a precise and direct measure of the number of zero bits following the LSB before the first occurrence of a 1.",
        ],
      },
    },
  ],
  TimeComplexity: [
    {
      pointer: {
        title: 'Binary Conversion',
        lists: [
          'String binaryString = Integer.toBinaryString(A);: The binary representation of an integer with n bits is obtained in O(n) time. Therefore, the binary conversion has a time complexity of O(log A), where A is the input integer.',
        ],
      },
    },
    {
      pointer: {
        title: 'Iteration through Binary String',
        lists: [
          'for (int  i = binaryString.length() - 1; i >= 0; i--) {: The loop iterates through each bit of the binary representation, which has a length of O(log A).',
          'Each iteration involves constant-time operations (charAt and comparisons).',
        ],
      },
    },
  ],
  optimizedTimeComplexity: [
    {
      pointer: {
        title: 'Loop Iteration',
        lists: [
          'The loop runs for a constant number of iterations (32 times), regardless of the value of the input integer A.',
          'Therefore, the loop iteration has a constant time complexity of O(1).',
        ],
      },
    },
    {
      pointer: {
        title: 'Bitwise Shift and Check Operations',
        lists: [
          'Inside the loop, there are constant-time operations such as bitwise right shift (>>), bitwise AND (&), and equality checks.',
          'These operations are performed a constant number of times (32 times), and each operation takes constant time.',
        ],
      },
    },
    {
      pointer: {
        title: 'Overall Time Complexity',
        lists: [
          'The dominant factor in the time complexity is the loop iteration, which is a constant number of times (32 times) regardless of the size of the input integer A.',
          'Therefore, the overall time complexity of the solve method is O(1).',
        ],
      },
    },
  ],
};

export const IterateItConstants = {
  Constraint01: ['1<=n<=10^5', '1<=ai<=5x10^4 for all 1<=i<=n'],
  Constraint02: [
    {
      title: 'O(n^3)',
      text: 'The pseudocode involves a nested loop where, for each pair of elements in array A, it performs a comparison and appends the absolute difference to array B. The worst-case time complexity is cubic due to the nested loops.',
    },
    {
      title: 'O(n^2 log n)',
      text: "If the array A is sorted or certain optimizations are made, the inner loop's complexity could be reduced to O(log n), resulting in an overall time complexity of O(n^2 log n).",
    },
    {
      title: 'O(n^2)',
      text: 'Considering the worst-case scenario where no optimizations are made, the time complexity would be quadratic due to the nested loops.',
    },
  ],
  Intuition01: [
    'The program iteratively processes the input array, identifying consecutive numbers and normalising them by dividing by their GCD.',
    'It checks if the normalised numbers form a consecutive sequence starting from 1.',
    'If not, it updates the differences between the numbers and repeats the process.',
    'The loop continues until a consecutive sequence is found, and the result is the total number of iterations.',
  ],
  Explanation: [
    {
      title: 'Import Statements',
      pointers: ['Import tools needed for the program.'],
    },
    {
      title: 'Main Class',
      pointers: [
        'Start the main part of the program.',
        "The structural foundation of the program is established through the definition of the main class. This class encapsulates the entire logic and functions of the program, serving as the central hub from which the program's operations are coordinated. It signifies the start of the program's definition.",
      ],
    },
    {
      title: 'Main Method',
      pointers: [
        'Begin the main part of the program where the execution starts.',
        "Within the main class, the main method is declared. This method is the entry point of the program, where the execution begins. It is responsible for initializing the program's environment, handling inputs, and triggering the program's core functionalities. The main method orchestrates the flow of execution from start to end.",
      ],
    },
    {
      title: 'Input Handling',
      pointers: [
        'Ask the user for a number (n).',
        'Create a list (a) to store n integers provided by the user.',
        'Create a checklist (c) to keep track of which numbers are present.',
      ],
    },
    {
      title: 'Main Loop',
      pointers: [
        'Create a variable (ret) to store the result and set it to 0.',
        'Enter a loop that continues indefinitely.',
        'A variable ret is initialized to store the result of the program, initially set to 0. The program then enters an indefinite loop, within which the primary logic for finding and analyzing sequences of consecutive numbers is implemented.',
      ],
    },
    {
      title: 'Find Consecutive Numbers',
      pointers: [
        'Look through the checklist and find consecutive numbers.',
        'Store these numbers in a list (v) and mark them as not present in the checklist.',
        'The algorithm scans the checklist to identify consecutive numbers. These numbers are then stored in a list v, and their presence is marked as not present in the checklist to avoid reprocessing. This step is crucial for isolating sequences of interest for further analysis',
      ],
    },
    {
      title: 'Calculate GCD',
      pointers: [
        'Find the Greatest Common Divisor (GCD) of the numbers in the list (v).',
        'For each identified sequence, the program calculates the Greatest Common Divisor (GCD) of the numbers within the list v. This calculation is vital for normalizing the numbers in subsequent steps.',
      ],
    },
    {
      title: 'Normalise Numbers',
      pointers: [
        'Adjust the numbers in the list (v) by dividing each by the GCD.',
        'The numbers in the list v are normalized by dividing each by the calculated GCD. This normalization process adjusts the numbers to simplify the sequence, making it easier to check for consecutive patterns.',
      ],
    },
    {
      title: 'Check for Consecutive Sequence',
      pointers: [
        'Check if the normalised numbers form a sequence starting from 1.',
        'The program checks if the normalized numbers form a consecutive sequence starting from 1. This step verifies whether the identified and normalized sequence meets the criteria for being considered a valid sequence.',
      ],
    },
    {
      title: 'Update Result and Break',
      pointers: [
        'If the numbers form a sequence, add the count to the result and end the loop.',
        "If a valid consecutive sequence is identified, the count of numbers in the sequence is added to the result ret, and the loop is terminated. This step concludes the search for consecutive sequences, as a successful find achieves the program's goal.",
      ],
    },
    {
      title: 'Update Differences',
      pointers: [
        "If the numbers don't form a sequence, update the differences between the numbers.",
        'If the numbers do not form a valid sequence, the program updates the differences between the numbers. This action adjusts the tracking of sequences to account for any discrepancies that prevented the sequence from being considered valid.',
      ],
    },
    {
      title: 'Increment Result',
      pointers: [
        'Increase the result count for each iteration of the loop.',
        'For each iteration of the loop where a sequence is processed but does not meet the criteria, the result count ret is incremented. This increment reflects the ongoing effort to find a valid sequence.',
      ],
    },
    {
      title: 'Print Result',
      pointers: [
        'Display the final result after exiting the loop.',
        "Upon exiting the loop, either through identifying a valid sequence or exhausting the possibilities, the final result ret is displayed to the user. This output represents the culmination of the program's operations, providing the answer to the problem it was designed to solve.",
      ],
    },
    {
      title: 'End of Main Method',
      pointers: [
        'Finish the main part of the program.',
        "The conclusion of the main method marks the end of the program's execution flow. It signifies that all logical operations and processes within the program have been completed.",
      ],
    },
    {
      title: 'End of Class',
      pointers: ['End the main class.'],
    },
  ],
  TimeComplexity: [
    {
      title: 'Input Reading',
      pointers: ['Reading n integers: O(n)'],
    },
    {
      title: 'Main Loop',
      pointers: [
        'The main loop runs until the break statement is executed',
        'In each iteration of the loop:',
        'Finding consecutive numbers in c: O(50000)',
        'Calculating GCD: O(size of v)',
        'Normalising numbers in v: O(size of v)',
        'Checking for consecutive sequence: O(size of v)',
        'Updating differences: O(size of v^2) in the worst case (nested loop)',
        'Incrementing the result: O(1)',
      ],
    },
    {
      title: 'GCD Calculation',
      pointers: [
        'The gcd function takes constant time as it is a simple Euclidean algorithm: O(log(min(x, y)))',
      ],
    },
    {
      title: 'Total Complexity',
      pointers: [
        'The overall time complexity of the main loop is dominated by the nested loops when updating differences.',
        'The size of v decreases over iterations, but in the worst case, it can be assumed to be roughly proportional to n.',
        'Therefore, the overall time complexity is approximately O(n^2).',
      ],
    },
    {
      title: 'Space Complexity',
      pointers: [
        'The space complexity is mainly determined by the boolean array c and the ArrayList v.',
        'Boolean array c has a constant size of 50009: O(1).',
        'ArrayList v can have a maximum size of 50000 in each iteration: O(50000) in the worst case.',
        'Overall space complexity is O(50000).',
      ],
    },
  ],
  SpaceComplexity: [
    {
      title: 'Explanation',
      pointers: [
        "The array a has a space complexity of O(n) as it stores 'n' elements.",
        'The boolean array c has a space complexity of O(50009), which can be considered constant since its size is fixed and does not depend on the input size.',
        'The ArrayList v is initialized and used to collect elements from the boolean array. Its space complexity is proportional to the number of distinct elements in the array, and in the worst case, it can be O(n)',
        'Other variables such as ret, g, flag, i, j, etc., are scalar variables with constant space complexity.',
      ],
    },
  ],
};

export const TricksWithBitsConst = [
  {
    title: 'x ^ ( x & (x-1))',
    pointer: [
      'Returns the rightmost 1 in the binary representation of x.',
      'Example: For x = 10 (binary: 1010), result = 10 ^ 8 = 2 (binary: 0010).',
    ],
  },
  {
    title: 'x & (-x)',
    pointer: [
      'Returns the rightmost 1 in the binary representation of x.',
      'Example: For x = 10 (binary: 1010), result = 10 & (-10) = 2 (binary: 0010).',
    ],
  },
  {
    title: 'x | (1 << n)',
    pointer: [
      'Sets the nth bit in the binary representation of x.',
      'Example: For x = 10 (binary: 1010) and n = 2, result = 10 | (1 << 2) = 14 (binary: 1110).',
    ],
  },
];
