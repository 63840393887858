// @ts-nocheck

import SingleCorrectQuestion from './SingleCorrectQuestion';
import TextResponse from './TextResponse';

// Question at index 0 will be shown if the user gave incorrect response for the previous question and index 1 if the user gave correct response,
export const questionTree = {
  1: ['2b', '2a'],
  '2a': ['3b', '3a'],
  '2b': ['3d', '3c'],
  '3a': ['4b', '4a'],
  '3b': ['4d', '4c'],
  '3c': ['4f', '4e'],
  '3d': ['4h', '4g'],
  '4a': ['5', '5'],
  '4b': ['5', '5'],
  '4c': ['5', '5'],
  '4d': ['5', '5'],
  '4e': ['5', '5'],
  '4f': ['5', '5'],
  '4g': ['5', '5'],
  '4h': ['5', '5'],
  5: [],
};

// https://stackoverflow.com/a/60610458/11630586
// Root node is assumed to be at height 0.
export const getNodeDepth = (node) => {
  return parseInt(`${node}`) - 1;
};

export const getQuestionDescription = (qid) => {
  return screeningQuestions[qid]?.description ?? '';
};

const screeningQuestions = {
  1: {
    description:
      'Which of the following best describes the behavior of a stack?',
    options: [
      {
        option: 'First In Last Out',
        isCorrect: false,
      },
      {
        option: 'Last In First Out',
        isCorrect: false,
      },
      {
        option: 'Both (A) & (B)',
        isCorrect: true,
      },
      {
        option: 'Last In Last Out',
        isCorrect: false,
      },
    ],
    getComponent: (args) => <SingleCorrectQuestion {...args} />,
    type: 'single_correct',
  },
  '2a': {
    description:
      'What would be the result after performing the following sequence of stack operations: push(1), push(2), pop(), push(3), pop()?',
    options: [
      {
        option: '1 remains on the top of the stack',
        isCorrect: true,
      },
      {
        option: '3 remains on the top of the stack',
        isCorrect: false,
      },
      {
        option: '2 remains on the top of the stack',
        isCorrect: false,
      },
      {
        option: 'The stack is empty',
        isCorrect: false,
      },
    ],
    getComponent: (args) => <SingleCorrectQuestion {...args} />,
    type: 'single_correct',
  },
  '2b': {
    description:
      'Which of the following is not a valid variable name in most programming languages?',
    options: [
      {
        option: 'var_name',
        isCorrect: false,
      },
      {
        option: '2variable',
        isCorrect: true,
      },
      {
        option: 'variable2',
        isCorrect: false,
      },
      {
        option: '_var',
        isCorrect: false,
      },
    ],
    getComponent: (args) => <SingleCorrectQuestion {...args} />,
    type: 'single_correct',
  },
  '3a': {
    description:
      'For managing hierarchical content in a CMS, which data structure is best suited? ',
    options: [
      {
        option: 'Queue',
        isCorrect: false,
      },
      {
        option: 'Stack',
        isCorrect: false,
      },
      {
        option: 'Tree',
        isCorrect: true,
      },
      {
        option: 'Hash Map',
        isCorrect: false,
      },
    ],
    getComponent: (args) => <SingleCorrectQuestion {...args} />,
    type: 'single_correct',
  },
  '3b': {
    description:
      'What is the advantage of using a graph database over a relational database?',
    options: [
      {
        option: 'Faster joins',
        isCorrect: false,
      },
      {
        option: 'Better at handling structured data',
        isCorrect: false,
      },
      {
        option: 'Improved transactional integrity',
        isCorrect: false,
      },
      {
        option: 'Efficient representation of interconnections',
        isCorrect: true,
      },
    ],
    getComponent: (args) => <SingleCorrectQuestion {...args} />,
    type: 'single_correct',
  },
  '3d': {
    description:
      "An investor receives an annual interest payout from a bond which is 5% of the bond's face value. If the annual interest received is $600, what is the face value of the bond?",
    options: [
      {
        option: '$10,000',
        isCorrect: false,
      },
      {
        option: '$12,000',
        isCorrect: true,
      },
      {
        option: '$15,000',
        isCorrect: false,
      },
      {
        option: '$20,000',
        isCorrect: false,
      },
    ],
    getComponent: (args) => <SingleCorrectQuestion {...args} />,
    type: 'single_correct',
  },
  '3c': {
    description: 'What is a function that calls itself called?',
    options: [
      {
        option: 'Loop Function',
        isCorrect: false,
      },
      {
        option: 'Recursion',
        isCorrect: true,
      },
      {
        option: 'Callback Function',
        isCorrect: false,
      },
      {
        option: 'Inline Function',
        isCorrect: false,
      },
    ],
    getComponent: (args) => <SingleCorrectQuestion {...args} />,
    type: 'single_correct',
  },
  '4b': {
    description:
      'Which sorting algorithm is generally considered the fastest when dealing with large datasets?',
    options: [
      {
        option: 'Bubble Sort',
        isCorrect: false,
      },
      {
        option: 'Quick Sort',
        isCorrect: true,
      },
      {
        option: 'Insertion Sort',
        isCorrect: false,
      },
      {
        option: 'Merge Sort',
        isCorrect: false,
      },
    ],
    getComponent: (args) => <SingleCorrectQuestion {...args} />,
    type: 'single_correct',
  },
  '4a': {
    description:
      'To implement a file system with quick file access, immediate insertion, and frequent size checks of the largest file, which data structure is suited?',
    options: [
      {
        option: 'B-tree',
        isCorrect: true,
      },
      {
        option: 'Heap',
        isCorrect: false,
      },
      {
        option: 'Hash table',
        isCorrect: false,
      },
      {
        option: 'Binary search tree',
        isCorrect: false,
      },
    ],
    getComponent: (args) => <SingleCorrectQuestion {...args} />,
    type: 'single_correct',
  },
  '4d': {
    description:
      'Which concept is typically used to repeat a block of code multiple times until a certain condition is met?',
    options: [
      {
        option: 'Inheritance',
        isCorrect: false,
      },
      {
        option: 'Abstraction',
        isCorrect: false,
      },
      {
        option: 'Loop',
        isCorrect: true,
      },
      {
        option: 'Encapsulation',
        isCorrect: false,
      },
    ],
    getComponent: (args) => <SingleCorrectQuestion {...args} />,
    type: 'single_correct',
  },
  '4c': {
    description: 'Which of these is not a programming paradigm?',
    options: [
      {
        option: 'Object-oriented',
        isCorrect: false,
      },
      {
        option: 'Functional',
        isCorrect: false,
      },
      {
        option: 'Procedural',
        isCorrect: false,
      },
      {
        option: 'Quantum',
        isCorrect: true,
      },
    ],
    getComponent: (args) => <SingleCorrectQuestion {...args} />,
    type: 'single_correct',
  },
  '4f': {
    description:
      'Which SQL clause is used to filter the results of a query based on a specified condition?',
    options: [
      {
        option: 'SELECT',
        isCorrect: false,
      },
      {
        option: 'FROM',
        isCorrect: false,
      },
      {
        option: 'WHERE',
        isCorrect: true,
      },
      {
        option: 'ORDER BY',
        isCorrect: false,
      },
    ],
    getComponent: (args) => <SingleCorrectQuestion {...args} />,
    type: 'single_correct',
  },
  '4e': {
    description:
      'Which operation would you use to combine two strings into one?',
    options: [
      {
        option: 'Summation',
        isCorrect: false,
      },
      {
        option: 'Concatenation',
        isCorrect: true,
      },
      {
        option: 'Multiplication',
        isCorrect: false,
      },
      {
        option: 'Compression',
        isCorrect: false,
      },
    ],
    getComponent: (args) => <SingleCorrectQuestion {...args} />,
    type: 'single_correct',
  },
  '4h': {
    description: 'What is the result of the operation 3%2?',
    options: [
      {
        option: '3',
        isCorrect: false,
      },
      {
        option: '0',
        isCorrect: false,
      },
      {
        option: '1',
        isCorrect: true,
      },
      {
        option: '2',
        isCorrect: false,
      },
    ],
    getComponent: (args) => <SingleCorrectQuestion {...args} />,
    type: 'single_correct',
  },
  '4g': {
    description:
      'A project was estimated to take 10 hours but was completed in 8. By what percent did the efficiency increase?',
    options: [
      {
        option: '20%',
        isCorrect: true,
      },
      {
        option: '25%',
        isCorrect: false,
      },
      {
        option: '50%',
        isCorrect: false,
      },
      {
        option: '80%',
        isCorrect: false,
      },
    ],
    getComponent: (args) => <SingleCorrectQuestion {...args} />,
    type: 'single_correct',
  },
  5: {
    description:
      'Describe a time when you had to learn a new technology or concept on your own to complete a project. What resources did you use, and how did you ensure you understood it thoroughly?',
    maxCharacters: 1000,
    getComponent: (args) => <TextResponse {...args} />,
    type: 'text',
  },
};

export default screeningQuestions;
