// @ts-nocheck
import React, { useState } from 'react';
import axios from 'axios';
import { Box, Skeleton } from '@mui/material';
import TermsAndCondtitionsLayout from '../../layouts/termsAndConditions/TermsAndConditionsLayout';
import { useEfPipeline } from '../../layouts/EfPipelineContext/EfPipelineContext';
import { QuestionElement } from './QuestionElement';
import { getHeaders, postEligibilityForm } from '../../apis/urls';
import ErrorNotifier from '../ToastNotifications/ErrorNotifier';

export default function FormContainer({ questions, loading }) {
  const {
    setIsSuccess,
    setSuccessMessage,
    formId,
    setShowConversationFlow,
    setShowSopRevisit,
    setSopResponse,
  } = useEfPipeline();

  const [formValues, setFormValues] = useState({});
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (questionId, answer) => {
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [questionId]: answer,
    }));
  };

  const handleSubmit = (e) => {
    const body = {
      response: JSON.stringify(formatResponse(formValues)),
    };
    axios
      .post(postEligibilityForm(formId), body, {
        headers: getHeaders(),
      })
      .then((res) => {
        const {
          sop_answer,
          nudge_for_better_sop,
          show_conversation_flow = false,
        } = res?.data;
        setSopResponse(sop_answer);
        setShowSopRevisit(nudge_for_better_sop);
        setShowConversationFlow(show_conversation_flow);
        setIsSuccess(true);
        setSuccessMessage(
          res?.data?.message ||
            'Congratulations, your response has been received!'
        );

        setFormValues({});
        setSelectedOptions([]);
      })
      .catch((e) => {
        console.log(e);
        setHasError(true);
        setErrorMessage(
          e.response?.data?.message ||
            'An error occurred while submitting the response'
        );
      });
  };

  const formatResponse = (formValues) => {
    return questions.map((question) => ({
      question: question.title,
      answer: formValues[question.id],
    }));
  };

  const isAnyAnswerEmpty = questions.some((question) => {
    const value = formValues[question.id];
    return (
      value === undefined ||
      value === null ||
      (Array.isArray(value) && value.length === 0) ||
      (typeof value === 'string' && value.trim() === '')
    );
  });

  return (
    <>
      {hasError && (
        <ErrorNotifier
          message={errorMessage}
          setHasError={setHasError}
        />
      )}
      {loading ? (
        <Skeleton
          variant='rectangular'
          sx={{
            width: '90%',
            maxWidth: 800,
            height: 500,
            boxShadow: 1,
            p: 4,
            borderRadius: 5,
            mt: 4,
          }}
        />
      ) : (
        <Box
          sx={{
            width: '90%',
            maxWidth: 800,
            maxHeight: '90%',
            bgcolor: 'background.paper',
            boxShadow: 2,
            p: 4,
            borderRadius: 5,
            overflowY: 'auto',
            mt: 4,
          }}
        >
          <Box
            component='form'
            onSubmit={handleSubmit}
          >
            {questions.map((question) => (
              <QuestionElement
                key={question.id}
                question={question}
                onChange={handleChange}
                value={formValues[question.id] || ''}
                selectedOptions={selectedOptions}
                setSelectedOptions={setSelectedOptions}
              />
            ))}
            <TermsAndCondtitionsLayout
              handleSubmit={handleSubmit}
              isAnyAnswerEmpty={isAnyAnswerEmpty}
            />
          </Box>
        </Box>
      )}
    </>
  );
}
