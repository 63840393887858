import { Box, Stack } from '@material-ui/core';
import React from 'react';
import PreviousPage from '../../../../pages/PreviousPage';
import CodingTestCodeEditor from './CodingTestCodeEditor';
import { ProblemSubTabs } from './ProblemSubTabs';
import { NewProblemSubTabs } from './NewProblemSubTabs';
import NewCodeEditor from './NewCodeEditor';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

export default function CodingTestDetailedProblem({
  selectedProblem,
  setShowDetailedProblem,
  selectedTest,
  isTestInProgress,
}) {
  const currentProgram = useSelector((state) => state.programs.currentProgram);
  return (
    <Stack
      ml={2}
      mr={2}
    >
      <Box
        display='flex'
        justifyContent='space-between'
      >
        <PreviousPage
          handlePrevPageClick={() => setShowDetailedProblem(false)}
        />
      </Box>

      <Box sx={{ display: 'flex', width: '100%', gap: '5px' }}>
        <NewProblemSubTabs selectedProblem={selectedProblem} />
        <NewCodeEditor
          selectedProblem={selectedProblem}
          problemContext={'Tgp'}
        />
      </Box>
    </Stack>
  );
}
